import 'element/css/select.scss'

import Select from 'element-ui/packages/select/src/select'

// решает проблему с отсутствием клавиатуры при фокусе на поле
Select.computed.readonly = function () {
  // trade-off for IE input readonly problem: https://github.com/ElemeFE/element/issues/10403
  // const isIE = !this.$isServer && !Number.isNaN(Number(document.documentMode))
  // эти комментарии не удалять, могут сгодиться

  return !this.filterable && !this.remote
}

// решает проблему с несворачивающимся селектом при filterable=true
Select.methods.handleFocus = function (event) {
  if (!this.softFocus) {
    if (this.automaticDropdown || (this.filterable && !this.visible)) {
      this.visible = true
      this.menuVisibleOnFocus = true
    }
    this.$emit('focus', event)
  }
  else {
    this.softFocus = false
  }
}

// изначально el-select не поддерживает валидацию поля по blur
Select.methods.handleBlur = function (event) {
  setTimeout(() => {
    this.dispatch('ElFormItem', 'el.form.blur', [this.value])

    if (this.isSilentBlur) {
      this.isSilentBlur = false
    }
    else {
      this.$emit('blur', event)
    }
  }, 200)
  this.softFocus = false
}

export default Select
